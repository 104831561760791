<template>
    <b-row class="mb-3">
        <div class="col d-flex">
            <div>
                <button
                    :disabled="quantity === 0"
                    @click="removeFromRule"
                    class="btn btn-outline-danger btn-small mr-2 px-3 py-1">
                    -
                </button>
                    <span class="mx-2 font-weight-bold">{{quantity}}</span>
                <button
                    @click="addToRule"
                    :disabled="selected.length === max"
                    class="btn btn-outline-success btn-small ml-2 px-3 py-1">
                    +
                </button>
            </div>
            <div class="ml-3 py-1">
                <span class="mx-2 font-weight-bold">{{product.name}}</span>
                <small v-if="product.extra_price">( + &euro; {{(product.extra_price / 100).toFixed(2)}} )</small>
            </div>
        </div>
    </b-row>
</template>

<script>
export default {
    props: ['product', 'selected', 'max'],
    data() {
        return {
           quantity: 0
        }
    },
    methods: {
        addToRule() {
            this.quantity++
            this.$emit('increment')
            
        },
        removeFromRule() {
            this.quantity--
            this.$emit('decrement')
        }
    }
}
</script>