<template>
    <div class="category-section">
        <a class="anchor" :id="`${toSlug(category.name)}`"></a>
        <b-row class="my-5">
            <b-col cols=12>
                <b-row>
                    <b-col cols="12">
                        <h2 class="font-weight-bold">{{ category.name }}</h2>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col v-if="products.length > 0" cols="12">
                        <b-row>
                            <product
                                    class="col-12"
                                    v-for="product in sortedProducts"
                                    :key="product._id"
                                    :product="product"
                            />
                        </b-row>
                    </b-col>
                    <b-col v-else class="col-lg-6">
                        <p>Er zijn geen producten in deze categorie...</p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<style>
    a.anchor {
        display: block;
        position: relative;
        top: -200px;
        visibility: hidden;
    }

    @media only screen and (max-width: 600px) {
        a.anchor {
            top: -150px;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        a.anchor {
            top: -200px;
        }
    }
</style>


<script>
    import Product from '@/components/Product'

    export default {
        name: 'CategorySection',
        props: ['category', 'products'],
        components: {Product},
        computed: {
            sortedProducts() {

                // Filter all the defined cateogries
                let list = this.products.slice().filter(obj => {
                    return obj.code !== null
                })

                // Sort the list
                list.sort((a, b) => {
                    return a.code.toString().localeCompare(b.code.toString(), navigator.languages[0] || navigator.language, {
                        numeric: true,
                        ignorePunctuation: true
                    })
                })

                // Add all undefined products to the end of the list
                list = list.concat(this.products.filter(x => x.code === null))

                return list.filter(x => x.visible === true)
            }
        },
        methods: {
            toSlug(string) {
                return string.toLowerCase().replace(/\//g, "").replace(/\s+/g, "-")
            }
        }
    }
</script>