<template>
    <div class="delivery">
        <section class="bgimage d-flex justify-content-center align-items-center text-white text-center">
            <b-container>
                <b-row>
                    <b-col>
                        <h1>Afhalen en bezorgen</h1>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section
                v-if="products && categories"
                class="d-flex justify-content-center bg-white"
        >
            <b-container fluid>

                <!-- Category bar -->
                <b-row class="category-bar">
                    <b-col>
                        <VueSlickCarousel ref="carousel" v-bind="settings">
                            <a draggable="false"
                               :href="`#${toSlug(category.name)}`"
                               :key="category._id"
                               class="m-2 p-3 font-weight-bold"
                               v-for="category in sortedCategories"
                            >
                                {{ category.name }}
                            </a>
                        </VueSlickCarousel>
                    </b-col>
                </b-row>

                <b-row ref="content">
                    <b-col cols="12">
                        <category-section
                                class="my-5 anchor"
                                v-for="category in sortedCategories"
                                :key="category._id"
                                :category="category"
                                :products="listProductsByCategory(category._id)"
                        />
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    import CategorySection from '@/components/CategorySection'
    import {mapGetters} from 'vuex'
    import VueSlickCarousel from "vue-slick-carousel"

    export default {
        name: "Delivery",
        components: {CategorySection, VueSlickCarousel},
        data() {
            return {
                settings: {
                    "focusOnSelect": true,
                    "infinite": false,
                    "variableWidth": true,
                    "swipeToSlide": true,
                    "arrows": false
                },
            }
        },
        created() {
            // Fetch categories
            this.$store.dispatch("fetchCategories");

            // Fetch products
            this.$store.dispatch("fetchProducts");
        },
        computed: {
            ...mapGetters([
                'categories',
            ]),
            products() {
                return this.$store.state.products
            },
            sortedCategories() {

                // Filter all the defined cateogries
                let list = this.categories.slice().filter(obj => {
                    return obj.index
                })

                // Sort the list
                list.sort((a, b) => {
                    return a.index.toString().localeCompare(b.index.toString(), navigator.languages[0] || navigator.language, {
                        numeric: true,
                        ignorePunctuation: true
                    })
                })

                // Add all undefined categories to the end of the list
                list = list.concat(this.categories.filter(x => x.index === undefined || x.index === null))

                return list.filter(x => x.visible === true)
            }
        },
        methods: {
            listProductsByCategory(category_id) {
                if (this.products && this.sortedCategories) {
                    return this.products.filter((x) => {
                        if (x.category) {
                            if (x.category._id === category_id && x.visible === true) {
                                return x
                            }
                        }
                    })
                }
            },
            toSlug(string) {
                return string.toLowerCase().replace(/\//g, "").replace(/\s+/g, "-")
            }
        }
    }
</script>

<style scoped>
    .delivery .bgimage {
        background: url('../assets/header/banner-delivery.jpg');
        background-size: cover;
    }

    .delivery.fixed {
        overflow-y: hidden;
    }

    .category-bar {
        position: -webkit-sticky;
        position: sticky;
        top: 14%;
        z-index: 999;
        background-color: #f7f1e3;
        overflow-x: scroll;
    }

    .category-bar a {
        color: #2c3e50;
    }

    @media only screen and (max-width: 667px) {
        .category-bar {
            top: 70px;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .category-bar {
            top: 90px;
        }
    }

    @media (orientation: landscape) and (min-width: 768px) and (max-width: 1024px) {
        .category-bar {
            top: 115px;
        }
    }

    .nav-pills .nav-link.active {
        background-color: #e74c3c;
        color: #ffffff;
    }
</style>