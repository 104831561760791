<template>
    <div v-if="product.visible">
        <b-row>
            <b-col :class="(cartOpen ? 'product col-12 col-md-6 col-lg-6' : 'product col-12')">
                <b-card
                        tag="article"
                        class="mt-3 rounded-0"
                >

                    <!-- Collapse Menu Rule button -->
                    <b-button
                            v-if="product.menu && product.menu.menu_rules.length > 0"
                            :class="visible ? null : 'collapsed'"
                            :aria-expanded="visible ? 'true' : 'false'"
                            aria-controls="collapse-4"
                            href="#"
                            variant="outline-light"
                            squared
                            style="position:absolute;top:0;right:0;border:1px solid rgba(0, 0, 0, 0.125);border-top:0;border-right:0"
                            @click="visible = !visible"
                    >

                        <b-icon-plus-circle v-if="!visible" class="text-primary"/>
                        <b-icon-x-circle v-else class="text-primary"/>

                    </b-button>

                    <!-- Add to cart button -->
                    <b-button
                            v-else
                            href="#"
                            variant="outline-light"
                            squared
                            style="position:absolute;top:0;right:0;border:1px solid rgba(0, 0, 0, 0.125);border-top:0;border-right:0"
                            @click="addToCart(product._id)"
                    >
                        <b-icon-plus-circle class="text-primary"/>
                    </b-button>

                    <div class="d-flex align-items-center">
                        <div class="product-information">
                            <h4>{{ this.product.name }}</h4>
                            <p class="font-weight-bold">{{ this.product.description }}</p>
                            <ul v-if="product.menu" class="list-unstyled">
                                <li v-for="menu_line in this.product.menu.menu_lines" :key="menu_line._id">
                                    {{ menu_line.quantity }}x {{ menu_line.product.name }}
                                </li>
                                <li v-for="menu_rule in this.product.menu.menu_rules" :key="menu_rule._id">
                                    {{ menu_rule.quantity }}x {{ menu_rule.name }}
                                </li>
                            </ul>
                            <p class="product-price">&euro; {{ productPrice }}</p>
                        </div>
                        <div class="ml-auto pr-4 product-image" style="min-width: 150px; max-width: 150px; width: 150px; height: 150px">
                            <b-img
                                    fluid-grow
                                    style="object-fit: contain; height: 100%"
                                    v-if="this.product.image"
                                    :src="getImgUrl(this.product.image)"
                                    v-bind:alt="this.product.name"
                            />
                        </div>
                    </div>
                </b-card>

                <!-- Menu Rules -->
                <b-collapse v-if="product.menu && product.menu.menu_rules" id="collapse-4" v-model="visible">
                    <b-card class="rounded-0 bg-light">

                        <!-- MenuRules -->
                        <MenuRule
                                v-for="menu_rule in product.menu.menu_rules"
                                :key="menu_rule._id"
                                :rule="menu_rule"
                                @change="(val) => handleMenuRule(val)"
                        />

                        <b-button
                                block
                                squared
                                :disabled="disabled"
                                variant="primary"
                                class="font-weight-bold"
                                @click="addToCart(product._id, editedRules)"
                        >
                            &euro; {{(parseFloat(productPrice) + parseFloat(totalExtraPrice)).toFixed(2)}}
                        </b-button>

                    </b-card>
                </b-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
    .product-price {
        color: #ff6348;
        font-weight: bold;
    }
</style>

<script>
    import MenuRule from '@/components/MenuRule'
    import {mapGetters} from 'vuex'

    const images = require.context('../assets/products/', false, /.*\.(png|jpg)$/)

    export default {
        props: ['product'],
        name: 'Product',
        components: {
            MenuRule
        },
        data() {
            return {
                visible: false,
                editedRules: [],
                disabled: true,
            }
        },
        computed: {
            ...mapGetters([
                "cartOpen"
            ]),
            productPrice() {
                // return ((this.product.price + this.product.total_extra_price) / 100).toFixed(2)
                return (this.product.price / 100).toFixed(2)
            },
            // Counts the total extra price when this is applied in the menu rule
            totalExtraPrice() {
                return (this.editedRules.reduce((total, rule) => {
                    return total + rule.extra_price
                }, 0) / 100).toFixed(2)
            }
        },
        methods: {
            getImgUrl(file_name) {
                try {
                    file_name = file_name.replace(/png|jpg/ig, match => match.toLowerCase())
                    return images('./' + file_name)
                } catch (e) {
                    return null
                }
            },
            // Method to catch the selected products in rule
            handleMenuRule(val) {

                // Search index of editedRule
                let index = this.editedRules.findIndex((item) => item._id === val._id)

                // If index is not found push into array
                if (index === -1) {
                    this.editedRules.push(val)
                }

                // Update the editedRule
                else {
                    this.editedRules[index] = val
                }

                // Check if every rule is valid
                if (this.editedRules.length === this.product.menu.menu_rules.length) {
                    this.disabled = (this.editedRules.some(e => e.valid === false) ? true : false)
                } else {
                    this.disabled = true
                }
            },
            addToCart(id, editedRules = []) {
                this.$store.dispatch("addToCart", {productId: id, editedRules});
            }
        }
    }
</script>