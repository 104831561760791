<template>
    <b-row class="mb-3">
        <b-col col lg="12">
            <b-row>
                <b-col col lg="12">
                    <p>{{rule.quantity}}x {{rule.name}}</p>
                </b-col>
            </b-row>
            <div v-if="rule.quantity > 1">
                <MenuRuleProduct 
                    v-for="product in rule.products" 
                    :key="product._id"
                    :product="product"
                    :max="rule.quantity"
                    :selected="editedRule.selected"
                    @increment="addSelected(product)"
                    @decrement="removeSelected(product)"
                />
            </div>
            <div v-else>
                <b-form-select 
                    :options="options(rule.products)"
                    value-field="_id"
                    text-field="name"
                    v-model="editedRule.selected"
                >
                    <template v-slot:first>
                        <b-form-select-option 
                            :value="null" 
                            disabled>Selecteer een {{ rule.name }}
                        </b-form-select-option>
                    </template>
                    
                </b-form-select>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import MenuRuleProduct from '@/components/MenuRuleProduct'
import {mapGetters} from 'vuex'
export default {
    props: ['rule'],
    components: {
        MenuRuleProduct
    },
    data() {
        return {
            editedRule: {
                _id: this.rule._id,
                name: this.rule.name,
                quantity: this.rule.quantity,
                valid: false,
                extra_price: 0,
                selected: []
            }
        }
    },
    created() {
        // to default select the first product in selectbox if rule quantity is 1
        if(this.rule.quantity === 1) {
            this.editedRule.selected = null
        }
    },
    computed: {
         ...mapGetters([
            "products"
        ]),
    },
    watch: {
        'editedRule.selected': {
            handler(val) {

                // Default false
                this.editedRule.valid = false

                // Check if editedRule has any selected products
                if(this.editedRule.selected) {

                    // Watch if selected if filled when rule quantity is 1 (Selectbox)
                    if(this.rule.quantity === 1 && val) {
                        
                        // Set editedRule valid to true
                        this.editedRule.valid = true

                        // Add extra price if any is applied
                        let product = this.products.find(product => product._id === val)
                        this.editedRule.extra_price = (product.extra_price ? product.extra_price : 0)
                    }

                    // Watch if max quantity is hit then change the editedRule to valid
                    else if(val.length === this.rule.quantity) {
                        this.editedRule.valid = true
                    }

                    // Emit to parent
                    this.$emit('change', this.editedRule)
                }
            },
        }
    },
    methods: {
        addSelected(product) {
            // Add extra price if any is applied
            this.editedRule.extra_price += (product.extra_price ? product.extra_price : 0)
            this.editedRule.selected.push(product._id)
        },
        removeSelected(product) {
            // Remove extra price if any is applied
            this.editedRule.extra_price -= (product.extra_price ? product.extra_price : 0)

            // Find the index of the item to be removed
            const index = this.editedRule.selected.indexOf(product._id)
            // Splice the item
            this.editedRule.selected.splice(index, 1)
        },
        // Format the options for the selectbox (Mainly change the name with extra price)
        options(products) {
            let options = []
            products.map(val => {
                // Format the selectbox name with the price
                if(val.extra_price) {
                    options.push({_id: val._id, name: `${val.name} (+€ ${(val.extra_price / 100).toFixed(2)})`})
                }
                // No special treatment push to options
                else {
                    options.push({_id: val._id, name: val.name})
                }
            })
            return options
        }
    }
}
</script>